import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Loading, Toast } from 'antd-mobile'
import { Provider, useLocalObservable } from 'mobx-react'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useContext, useState } from 'react'
import { AliveScope } from 'react-activation'

import {
  // BrowserRouter,
  // HashRouter,
  unstable_HistoryRouter as HistoryRouter
} from 'react-router-dom'

import { NetworkStatus } from '@/components'
import ErrorBoundary from '@/components/ErrorBoundary'
import Routers from '@/router'
import { storeWithContext } from '@/store'
import { commonNav, getEnvironmentFromUrl, isPrdEnv } from '@/utils'
// import dictStore from '@/store/global/dictStore'
import { getToken, history, hybStorage, initApiDevEnv } from '@/utils'
import getQuery from '@/utils/getQuery'
import logger from '@/utils/logger'

import './app.less'
import { EventManagerProvider } from './components/EventManagerProvider'
import { bridge } from './utils/bridge'

// TODO hash  /  history router
// const HistroyRouter = __HASH_MODE__ ? HashRouter : BrowserRouter
declare const __ROUTE_PREFIX__: string

function App() {
  const store = useContext(storeWithContext)
  // const dict_store = useLocalObservable(() => dictStore)
  const [finished, setFinished] = useState(false)
  const init = async () => {
    const token = decodeURIComponent(getQuery('token') || '')
    const hyb_dev_env = decodeURIComponent(getQuery('hyb_dev_env') || '')
    const corpId = decodeURIComponent(getQuery('corpId') || '')
    const agentId = decodeURIComponent(getQuery('agentId') || '')
    initApiDevEnv(hyb_dev_env)
    console.log('inityixia:', corpId)
    // * 判断是否在PC端，并且不是iframe内打开
    console.log('bridge.isPc:', bridge.isPc, bridge.isIFrame)
    if (corpId && bridge.isPc && !bridge.isIFrame) {
      const env = getEnvironmentFromUrl(location.href)
      const url = isPrdEnv
        ? 'https://cloud.huiyunban.cn/schedule/#/'
        : `https://cloud-test.huiyunban.cn/fe-schedule-pc-${env}/index.html#/`
      window.location.href = `${url}?${location.search.substring(1) || ''}`
      return
    }
    if (token) {
      // for 微信小程序
      await store.urlLogin()
    } else if (corpId) {
      // for 钉钉、飞书、企微
      await store.webviewLogin(corpId, hyb_dev_env, agentId)
    }
    setFinished(true)
  }

  useEffect(() => {
    // 使用 performance.navigation.type
    if (performance && performance.navigation && performance.navigation.type) {
      switch (performance.navigation.type) {
        case 0:
          console.log('页面是正常加载')
          break
        case 1:
          console.log('页面是通过 location.reload() 刷新')
          // commonNav.navigateBack()
          break
        case 2:
          console.log('页面是通过后退/前进按钮加载')
          break
        default:
          console.log('未知的加载类型')
      }
    }
    init()
  }, [])

  const queryClient = new QueryClient()

  if (!finished) {
    return <Loading className='init-loading' />
  }
  console.log('运行app...')
  return (
    // 错误边界
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <HistoryRouter history={history as any} basename={__ROUTE_PREFIX__}>
          <Provider store={store}>
            <EventManagerProvider>
              <NetworkStatus />
              <AliveScope>
                <Routers />
              </AliveScope>
            </EventManagerProvider>
          </Provider>
        </HistoryRouter>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    </ErrorBoundary>
  )
}

export default observer(App)
