import { lazy } from 'react'

const Apply: Array<any> = [
  {
    path: '/apply',
    meta: {
      title: '应用'
    },
    children: [
      {
        path: '/apply/qrcode',
        element: lazy(() => import(/* webpackChunkName: "apply/qrcode" */ '@/pages/apply/qrcode')),
        meta: {
          requiresAuth: true,
          title: '二维码打卡',
          key: 'qrcode',
          noSafeArea: true
        }
      },
      {
        path: '/apply/myInventory',
        element: lazy(
          () => import(/* webpackChunkName: "apply/myInventory" */ '@/pages/apply/myInventory')
        ),
        meta: {
          requiresAuth: true,
          title: '库存余额',
          key: 'myInventory',
          noSafeArea: true
        }
      },
      {
        path: '/apply/inventoryDetails',
        element: lazy(
          () =>
            import(
              /* webpackChunkName: "apply/inventoryDetails" */ '@/pages/apply/inventoryDetails'
            )
        ),
        meta: {
          requiresAuth: true,
          title: '库存明细',
          key: 'inventoryDetails',
          noSafeArea: true
        }
      }
    ]
  }
]

export default Apply
