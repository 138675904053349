import { lazy } from 'react'

const common: Array<any> = [
  {
    path: '/common',
    meta: {
      title: ''
    },
    children: [
      {
        path: '/common/form/:code',
        element: lazy(
          () => import(/* webpackChunkName: "commonForm" */ '@/pages/common/form/index')
        ),
        meta: {
          requiresAuth: true,
          title: '',
          key: 'commonForm',
          cacheSource: ['healthList']
        }
      }
    ]
  }
]

export default common
