import apply from './apply'
import attendance from './attendance'
import common from './common'
import demo from './demo'
import entry from './entry'
import flow from './flow'
import health from './health'
import my from './my'
import notify from './notify'
import recruit from './recruit'
import salary from './salary'
import schedule from './schedule'

export default [
  ...attendance,
  ...flow,
  ...recruit,
  ...schedule,
  ...entry,
  ...salary,
  ...notify,
  ...apply,
  ...my,
  ...demo,
  ...health,
  ...common
]
