import { lazy } from 'react'

import { RouteObject } from '@/router/types'

const Points: Array<RouteObject> = [
  {
    path: '/schedule',
    meta: {
      title: '排班'
    },
    children: [
      {
        path: '/schedule/advanced',
        element: lazy(
          () => import(/* webpackChunkName: "schedule/advanced" */ '@/pages/schedule/advanced')
        ),
        meta: {
          requiresAuth: true,
          cacheSource: ['scheduleAdvanced'],
          keepAlive: true,
          title: '移动排班',
          key: 'scheduleAdvanced'
        }
      },
      {
        path: '/schedule/template',
        element: lazy(
          () => import(/* webpackChunkName: "schedule/template" */ '@/pages/schedule/template')
        ),
        meta: {
          requiresAuth: true,
          cacheSource: ['scheduleAdvanced'],
          title: '排班模版',
          key: 'scheduleTemplate'
        }
      },
      {
        path: '/schedule/team',
        element: lazy(
          () => import(/* webpackChunkName: "schedule/team" */ '@/pages/schedule/team')
        ),
        meta: {
          requiresAuth: true,
          title: '团队排班',
          key: 'team'
        }
      },
      {
        path: '/schedule/my',
        element: lazy(() => import(/* webpackChunkName: "schedule/my" */ '@/pages/schedule/my')),
        meta: {
          requiresAuth: true,
          title: '我的排班',
          key: 'MySchedule'
        }
      },
      {
        path: '/schedule/hope',
        element: lazy(() => import(/* webpackChunkName: "scheduleHope" */ '@/pages/schedule/hope')),
        meta: {
          requiresAuth: true,
          title: '排班期望',
          key: 'scheduleHope'
        }
      }
    ]
  }
]

export default Points
