import { RouteObject } from '@/router/types'

import lazyLoad from '../utils/lazyLoad'

const health: Array<RouteObject> = [
  {
    path: '/health',
    meta: {
      title: '健康证管理'
    },
    children: [
      {
        path: '/health/list',
        element: lazyLoad(
          () => import(/* webpackChunkName: "healthList" */ '@/pages/health/index')
        ),
        meta: {
          requiresAuth: true,
          title: '健康证管理',
          key: 'healthList',
          keepAlive: true,
          cacheSource: ['healthList']
        }
      },
      {
        path: '/health/detail/:id',
        element: lazyLoad(
          () => import(/* webpackChunkName: "healthDetail" */ '@/pages/health/detail/index')
        ),
        meta: {
          requiresAuth: true,
          title: '健康证详情',
          key: 'healthDetail',
          cacheSource: ['healthList']
        }
      }
    ]
  }
]

export default health
