import { Dialog } from 'antd-mobile'
import { PropsWithChildren, ReactElement } from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'

import { isPrdEnv } from '@/utils'
import logger from '@/utils/logger'

function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  logger(error)
  const errorMessage = '抱歉，发生了一些小问题'
  const content = isPrdEnv ? errorMessage : error?.message || errorMessage
  Dialog.alert({
    content,
    confirmText: '刷新重试',
    onConfirm: () => {
      resetErrorBoundary()
    }
  })
  return <Dialog />
}
/**
 * 错误边界处理
 * @param param0
 * @returns
 */
export default function App({ children }: PropsWithChildren): ReactElement {
  return <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
}
