import { lazy } from 'react'

import { RouteObject } from '@/router/types'

const Attendance: Array<RouteObject> = [
  {
    path: '/flow',
    meta: {
      title: '考勤管理'
    },
    children: [
      {
        path: '/flow/approval',
        element: lazy(
          () => import(/* webpackChunkName: "flow/approval" */ '@/pages/Flow/Approval')
        ),
        meta: {
          requiresAuth: true,
          keepAlive: true,
          cacheSource: ['flowApproval'],
          title: '我的审批',
          key: 'flowApproval'
        }
      },
      {
        path: '/flow/apply',
        element: lazy(
          () => import(/* webpackChunkName: "pages/Flow/Apply" */ '@/pages/Flow/Apply/index')
        ),
        meta: {
          requiresAuth: true,
          title: '我的申请',
          key: '/flow/apply',
          noSafeArea: true
        }
      },
      {
        path: '/flow/applyList',
        element: lazy(
          () =>
            import(/* webpackChunkName: "pages/Flow/ApplyList" */ '@/pages/Flow/ApplyList/index')
        ),
        meta: {
          requiresAuth: true,
          keepAlive: true,
          title: '申请列表',
          cacheSource: ['flowApplyList'],
          key: 'flowApplyList'
        }
      },
      {
        path: '/flow/applyForm',
        element: lazy(
          () =>
            import(/* webpackChunkName: "pages/Flow/ApplyForm" */ '@/pages/Flow/ApplyForm/index')
        ),
        meta: {
          requiresAuth: true,
          title: '申请单据',
          cacheSource: [
            'flowApproval',
            'flowApplyList',
            'staffDaily',
            'teamDaily',
            'teamDailyDetail'
          ],
          key: 'flowApplyForm'
        }
      }
    ]
  }
]

export default Attendance
