import { lazy } from 'react'

import { RouteObject } from '@/router/types'

const entry: Array<RouteObject> = [
  {
    path: '/entry',
    meta: {
      title: '入职管理'
    },
    children: [
      {
        path: '/entry/fast',
        element: lazy(() => import(/* webpackChunkName: "entryFast" */ '@/pages/entry/fast/index')),
        meta: {
          requiresAuth: false,
          title: '快速入职',
          key: 'entryFast'
        }
      },
      {
        path: '/entry/result',
        element: lazy(
          () => import(/* webpackChunkName: "entryResult" */ '@/pages/entry/result/index')
        ),
        meta: {
          requiresAuth: false,
          title: '慧云班小助手',
          key: 'entryResult'
        }
      },
      {
        path: '/entry/stay',
        element: lazy(() => import(/* webpackChunkName: "entryStay" */ '@/pages/entry/stay/index')),
        meta: {
          requiresAuth: true,
          keepAlive: true,
          cacheSource: ['entryStay'],
          title: '待入职',
          key: 'entryStay'
        }
      },
      {
        path: '/entry/confirm',
        element: lazy(
          () => import(/* webpackChunkName: "entryConfirm" */ '@/pages/entry/confirm/index')
        ),
        meta: {
          requiresAuth: true,
          cacheSource: ['entryStay'],
          title: '确认入职',
          key: 'entryConfirm'
        }
      },
      {
        path: '/entry/modify',
        element: lazy(
          () => import(/* webpackChunkName: "entryConfirm" */ '@/pages/entry/confirm/index')
        ),
        meta: {
          requiresAuth: true,
          cacheSource: ['entryStay'],
          title: '修改信息',
          key: 'entryModify'
        }
      },
      {
        path: '/entry/signup',
        element: lazy(
          () => import(/* webpackChunkName: "entryConfirm" */ '@/pages/entry/confirm/index')
        ),
        meta: {
          requiresAuth: true,
          title: '快速报名',
          key: 'entrySignup'
        }
      }
    ]
  }
]

export default entry
